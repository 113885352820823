import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
  ModalBody,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ChevronDownIcon, Search2Icon, SettingsIcon } from "@chakra-ui/icons";
import { menuItems } from "../components/swapCard";
import CustomCard from "../components/customCard";
import CustomButton from "../components/customButton";
import CustomModal from "../components/customModal";
import {
  useAccount,
  useContractWrite,
  useContractRead,
  useBalance,
} from "wagmi";
import { contractData, fromWeiToDecimals } from "../utils/web3.util";
import { toWeiToDecimals } from "../utils/web3.util";
import { parseEther, parseUnits } from "viem";

const NewPosition = () => {
  const [selectedOption, setSelectedOption] = useState(1);
  const [selectedCard, setSelectedCard] = useState(1);
  const [fee, setFee] = useState("Fee tier");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cstInput, setCstInput] = useState("");
  const [tInput, setTInput] = useState("");
  const [isOpen2, setIsOpen2] = useState(false);
  const [depo1, setDepo1]: any = useState("");
  const [depo2, setDepo2]: any = useState("");
  const [searchInput, setSearchInput]: any = useState("");
  const [searchInput2, setSearchInput2]: any = useState("");
  const { address } = useAccount();

  // console.log("adress", address);
  // console.log("searchinput", searchInput)
  const value1 = depo1;
  // const value2 = parseUnits(depo2, 18);

  const [selectedCoin, setSelectedCoin]: any = useState();

  // console.log("selected coin1", selectedCoin)

  const [tokenName, setTokenName]: any = useState([]);
  const [tokenADecimals, setTokenADecimal]: any = useState();
  const [tokenNameB, setTokenNameB]: any = useState([]);
  const [tokenBDecimals, setTokenBDecimal]: any = useState();

  const [selectedCoin2, setSelectedCoin2]: any = useState();
  console.log("token a decimal", tokenNameB);
  // console.log("token b decimal", tokenBDecimals);

  const [isHidden, setIsHidden] = useState(false);
  const updateIsHidden = () => {
    setIsHidden(!isHidden);
  };
  const toggle = () => {
    setIsOpen2(!isOpen2);
  };

  const { data: TokenAname }: any = useContractRead({
    address: searchInput,
    abi: contractData?.tokenAABI,
    functionName: "name",
  });

  const { data: TokenADecimal }: any = useContractRead({
    address: searchInput,
    abi: contractData?.tokenAABI,
    functionName: "decimals",
  });

  const { data: TokenBname }: any = useContractRead({
    address: searchInput2,
    abi: contractData?.tokenBABI,
    functionName: "name",
  });

  const { data: TokenBDecimal }: any = useContractRead({
    address: searchInput2,
    abi: contractData?.tokenBABI,
    functionName: "decimals",
  });

  const { data: allowance } = useContractRead({
    address: searchInput,
    abi: contractData?.tokenAABI,
    functionName: "allowance",
    args: [address, contractData?.linkdaoDexRouterAddress],
    onError: (error: any) => {
      console.log("error", error);
    },
    watch: true,
  });
  // console.log("allownace1", fromWeiToDecimals(allowance));
  // console.log("dope 1", depo1)

  const { data: allowance2, isLoading: isAllowanceLoading2 } = useContractRead({
    address: searchInput2,
    abi: contractData?.tokenBABI,
    functionName: "allowance",
    args: [address, contractData?.linkdaoDexRouterAddress],
    onError: (error: any) => {
      console.log("error", error);
    },
    watch: true,
  });

  console.log("allownace2", allowance2);
  console.log("allownace1", allowance);
  // console.log("dope 2", depo2)

  const { data: token1Balance } = useContractRead({
    address: searchInput,
    abi: contractData?.tokenAABI,
    functionName: "balanceOf",
    args: [address],
    onError: (error: any) => {
      // toast.error(error?.message);
      console.log("error", error);
    },
    watch: true,
  });

  console.log(searchInput2, "search input 2");

  const { data: token2Balance } = useContractRead({
    address: searchInput2,
    abi: contractData?.tokenBABI,
    functionName: "balanceOf",
    args: [address],
    onError: (error: any) => {
      // toast.error(error?.message);
      console.log("error", error);
    },
    watch: true,
  });

  const { data: bnbBalance } = useBalance({
    address,
  });

  console.log(bnbBalance);

  const { write: approve, isLoading: isApproveLoading }: any = useContractWrite(
    {
      address: searchInput,
      abi: contractData?.tokenAABI,
      args: [contractData?.linkdaoDexRouterAddress, parseUnits(depo1, 18)],
      functionName: "approve",
      onError: (error: any) => {
        console.log("error", error);
      },
    }
  );

  const { write: approve2, isLoading: isApprove2Loading }: any =
    useContractWrite({
      address: searchInput2,
      abi: contractData?.tokenBABI,
      args: [contractData?.linkdaoDexRouterAddress, parseUnits(depo2, 18)],
      functionName: "approve",
      onError: (error: any) => {
        console.log("error", error);
      },
    });

  const { data: getPair }: any = useContractRead({
    address: contractData?.linkdaoDexFactoryAddress,
    abi: contractData?.linkdaiDexFactoryABI,
    functionName: "getPair",
    args: [
      selectedCoin === "BNB" ? contractData?.weth9Address : searchInput,
      searchInput2,
    ],
  });
  console.log("get pair", getPair);
  console.log(searchInput2, "dsssss");

  const { data: getReserves }: any = useContractRead({
    address: getPair,
    abi: contractData?.LinkdaoDexPairABI,
    functionName: "getReserves",
    select: (data) => {
      return {
        reserve1: data?.[0],
        reserve2: data?.[1],
        timestamp: data?.[2],
      };
    },
    watch: true,
  });

  const { data: quote, isLoading: quoteLoading }: any = useContractRead({
    address: contractData?.linkdaoDexRouterAddress,
    abi: contractData?.linkdaoDexRouterABI,
    functionName: "quote",
    args: [
      toWeiToDecimals(value1),
      getReserves?.reserve1,
      getReserves?.reserve2,
    ],
  });

  const { write: addLiquidty, isLoading: isLiquidtyLoading }: any =
    useContractWrite({
      address: contractData?.linkdaoDexRouterAddress,
      abi: contractData?.linkdaoDexRouterABI,
      functionName: "addLiquidity",
      args: [
        searchInput,
        searchInput2,
        parseUnits(depo1, tokenADecimals),
        parseUnits(depo2, tokenBDecimals),
        0,
        0,
        address,
        1824883364,
      ],
      onError: (error: any) => {
        console.log("error", error);
      },
    });

  const { write: addLiquidtyEth, isLoading: isLiquidtyLoadingEth }: any =
    useContractWrite({
      address: contractData?.linkdaoDexRouterAddress,
      abi: contractData?.linkdaoDexRouterABI,
      functionName: "addLiquidityETH",
      value: parseEther(value1?.toString()),
      args: [
        searchInput2,
        toWeiToDecimals(depo2?.toString(), tokenBDecimals),
        0,
        // toWeiToDecimals(value1?.toString(), 18),
        0,
        address,
        1824883364,
      ],
      onError: (error: any) => {
        console.log("error", error);
      },
    });

  useEffect(() => {
    setTokenName(TokenAname);
    setTokenADecimal(TokenADecimal);
  }, [searchInput]);

  useEffect(() => {
    setTokenNameB(TokenBname);
    setTokenBDecimal(TokenBDecimal);
  }, [searchInput2]);

  useEffect(() => {
    setDepo2(fromWeiToDecimals(quote));
  }, [quote]);

  // const num = parseFloat(depo1);

  // console.log("button depo",fromWeiToDecimals(allowance) > num)
  // console.log("tyoe" ,typeof num);
  // console.log("dep1", depo1);
  // console.log("alll", fromWeiToDecimals(allowance));

  return (
    <Box pb="100px" px="25px">
      <Box
        maxW="656px"
        p="20px"
        mx="auto"
        mt={"50px"}
        borderRadius={"15px"}
        border="1px solid #E2E8F0"
      >
        <Flex justify={"space-between"} align="center">
          <Link to={"/pool"}>
            <i className="fa-solid fa-arrow-left"></i>
          </Link>
          <Text fontSize={"18px"}>Add liquidity</Text>
          <Box>
            <Text
              fontSize={"12px"}
              display={"inline"}
              mr="10px"
              color={"#00E3C9"}
              cursor={"pointer"}
              onClick={() => {
                setSelectedCoin();
                setSelectedCoin2();
              }}
            >
              Clear All
            </Text>
            <Menu>
              <MenuButton as={Button} variant={"unstyled"}>
                <SettingsIcon />
              </MenuButton>
              <MenuList mt={"0px"} ml="-265px" w="322px" zIndex={2} p="10px">
                <Accordion>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          Max Slippage
                        </Box>
                        {selectedOption === 1 ? (
                          <Text>Auto</Text>
                        ) : (
                          <Text>{cstInput ? cstInput : "0"}%</Text>
                        )}
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <ButtonGroup isAttached>
                        <Button
                          fontSize={"12px"}
                          colorScheme={selectedOption === 1 ? "teal" : "gray"}
                          onClick={() => setSelectedOption(1)}
                        >
                          Auto
                        </Button>
                        <Button
                          fontSize={"12px"}
                          colorScheme={selectedOption === 2 ? "teal" : "gray"}
                          onClick={() => setSelectedOption(2)}
                        >
                          Custom
                        </Button>
                      </ButtonGroup>
                      <Input
                        w="48%"
                        ml="8px"
                        value={cstInput}
                        onChange={(e) => setCstInput(e.target.value)}
                        isDisabled={selectedOption === 1}
                      />
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          Transaction Deadline
                        </Box>
                        <Text>{tInput ? tInput : 10}m</Text>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Input
                        value={tInput}
                        type="number"
                        onChange={(e) => setTInput(e.target.value)}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
        <Divider my="20px" />
        <Box textAlign={"left"}>
          <Text>Select pair</Text>
          <Flex gap="5px" justify={"space-between"} my="20px" wrap="wrap">
            <Flex
              align={"center"}
              justify={"space-around"}
              gap="5px"
              h="45px"
              fontSize={"20px"}
              bg="white"
              color="black"
              border="1px solid #00E3C9"
              borderRadius={"20px"}
              onClick={onOpen}
              cursor={"pointer"}
              px="10px"
              w={{ base: "98%", md: "48%" }}
            >
              {/* <Image
                src={selectedCoin.imgSrc}
                h="18px"
                mb="-4px"
                display={"inline"}
                mx="5px"
              /> */}
              {/* <Text>{selectedCoin ? selectedCoin :"Select coin"}</Text> */}
              <Text>{selectedCoin || "Select coin"}</Text>
              <ChevronDownIcon />
            </Flex>
            <Flex
              align={"center"}
              justify={"space-around"}
              px="8px"
              h="45px"
              fontSize={"20px"}
              color="white"
              bg="#00E3C9"
              borderRadius={"20px"}
              onClick={toggle}
              cursor={"pointer"}
              w={{ base: "98%", md: "48%" }}
            >
              <Image
                // src={selectedCoin2.imgSrc}
                h="18px"
                mb="-4px"
                display={"inline"}
                mx="5px"
              />
              <Text>{selectedCoin2 ? selectedCoin2 : "Select coin"}</Text>
              <ChevronDownIcon />
            </Flex>
          </Flex>
          <Box position="relative">
            {/* Add a light overlay */}
            <Box
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              background="rgba(255, 255, 255, 0.5)" /* Adjust the opacity as needed */
              zIndex="1"
              display={
                selectedCoin == "Select Token" ||
                selectedCoin2 == "Select Token"
                  ? ""
                  : "none"
              }
            ></Box>
            <Box>
              <Text my="20px">Deposit amounts</Text>

              {selectedCoin ? (
                <Text textAlign="right">
                  {selectedCoin} Balance:{" "}
                  {selectedCoin === "BNB"
                    ? bnbBalance?.formatted
                    : fromWeiToDecimals(token1Balance)}
                </Text>
              ) : null}

              <CustomCard
                price="0"
                type="depo1"
                selectedCoin={selectedCoin}
                onOpen={onOpen}
                value={depo1}
                onChange={(e: { target: { value: string } }) => {
                  const value = e.target.value.toString();
                  setDepo1(value);
                }}
              />
              {selectedCoin2 ? (
                <Text textAlign="right">
                  {selectedCoin2} Balance: {fromWeiToDecimals(token2Balance)}
                </Text>
              ) : null}

              <CustomCard
                price="0"
                type="depo2"
                selectedCoin2={selectedCoin2}
                toggle={toggle}
                value={quoteLoading ? <Spinner /> : depo2}
                onChange={(e: any) => {
                  console.log(quote);
                  if (quote) {
                    return;
                  }
                  const value = e.target.value.toString();
                  setDepo2(value);
                }}
                readOnly={quote ? true : false}
              />
            </Box>
          </Box>
          {selectedCoin === "BNB" &&
            parseFloat(fromWeiToDecimals(allowance2?.toString())) >=
              parseFloat(depo2) && (
              <CustomButton
                text="Send Liquidity"
                w="100%"
                h="50px"
                borderRadius="15px"
                mt="20px"
                loading={isLiquidtyLoadingEth || isLiquidtyLoading}
                onClick={selectedCoin === "BNB" ? addLiquidtyEth : addLiquidty}
                // Add onClick handler for sending liquidity
              />
            )}
          {selectedCoin && selectedCoin2 && address && (
            <>
              {parseFloat(fromWeiToDecimals(allowance)) <= parseFloat(depo1) &&
              selectedCoin !== "BNB" ? (
                <CustomButton
                  text="Approve TKA"
                  w="100%"
                  h="50px"
                  borderRadius="15px"
                  mt="20px"
                  onClick={approve}
                  loading={isApproveLoading}
                />
              ) : null}

              {parseFloat(fromWeiToDecimals(allowance2)) < parseFloat(depo2) ? (
                <CustomButton
                  text="Approve TKB"
                  w="100%"
                  h="50px"
                  borderRadius="15px"
                  mt="20px"
                  onClick={approve2}
                  loading={isApprove2Loading}
                />
              ) : null}
              {parseFloat(fromWeiToDecimals(allowance)) > parseFloat(depo1) &&
                parseFloat(fromWeiToDecimals(allowance2)) >
                  parseFloat(depo2) && (
                  <CustomButton
                    text="Send Liquidity"
                    w="100%"
                    h="50px"
                    borderRadius="15px"
                    mt="20px"
                    loading={isLiquidtyLoadingEth || isLiquidtyLoading}
                    onClick={
                      selectedCoin === "BNB" ? addLiquidtyEth : addLiquidty
                    }
                    // Add onClick handler for sending liquidity
                  />
                )}
            </>
          )}
        </Box>
      </Box>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        body={
          <ModalBody>
            <InputGroup size="md" h="42px" w={"100%"}>
              <InputLeftElement pointerEvents="none">
                <Search2Icon color="gray.300" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search name or paste address"
                borderRadius="15px"
                _focus={{ borderColor: "#00E3C9" }}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </InputGroup>
            <ButtonGroup py="20px" gap="4px" flexWrap={"wrap"}>
              {/* Add a button for BNB */}
              {/* {selectedCoin === "BNB" ? (
                ""
              ) : ( */}
              <Button
                key="bnb"
                variant="solid"
                onClick={() => {
                  onClose();
                  setSelectedCoin("BNB"); // Update with your BNB selection logic
                  setSearchInput("");
                }}
              >
                BNB
              </Button>
              {/* )}   */}

              {/* Map through your token options and create buttons for each */}
              {menuItems.map((item, index) => (
                <Button
                  key={index}
                  variant="solid"
                  onClick={() => {
                    onClose();
                    setSelectedCoin(TokenAname); // Update with your token selection logic
                  }}
                >
                  {TokenAname}
                </Button>
              ))}
            </ButtonGroup>
            <Divider />
            <Box h="443px" mt="10px">
              <Flex
                align={"center"}
                gap="20px"
                bg="gray.100"
                p="10px"
                borderRadius={"10px"}
              >
                <Image
                  // src={selectedCoin.imgSrc}
                  h="18px"
                  mb="-4px"
                  display={"inline"}
                  mr="5px"
                />
                <Text>
                  {selectedCoin === "BNB" ? selectedCoin : TokenAname}
                </Text>
                <i
                  className="fa-solid fa-check"
                  style={{ marginLeft: "auto", color: "#00E3C9" }}
                ></i>
              </Flex>
            </Box>
          </ModalBody>
        }
      />

      <CustomModal
        isOpen={isOpen2}
        onClose={toggle}
        body={
          <ModalBody>
            <InputGroup size="md" h="42px" w={"100%"}>
              <InputLeftElement pointerEvents="none">
                <Search2Icon color="gray.300" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search name or paste address"
                borderRadius="15px"
                _focus={{ borderColor: "#00E3C9" }}
                onChange={(e) => setSearchInput2(e.target.value)}
              />
            </InputGroup>
            <ButtonGroup py="20px" gap="4px" flexWrap={"wrap"}>
              <Button
                variant="solid"
                onClick={() => {
                  toggle();
                  setSelectedCoin2(TokenBname);
                }}
              >
                {TokenBname}
              </Button>
              {menuItems.map((item, index) => (
                <Button
                  key={index}
                  variant="solid"
                  onClick={() => {
                    toggle();
                    setSelectedCoin2(item?.text);
                    if (item?.text === "USDT") {
                      setSearchInput2(
                        "0x55d1a26fd8bf702b796682a14fa4e63e00abcdcc"
                      );
                    }
                  }}
                >
                  {item?.text}
                </Button>
              ))}
            </ButtonGroup>
            <Divider />
            <Box h="443px" mt="10px">
              <Flex
                align={"center"}
                gap="20px"
                bg="gray.100"
                p="10px"
                borderRadius={"10px"}
              >
                <Image
                  // src={selectedCoin2.imgSrc}
                  h="18px"
                  mb="-4px"
                  display={"inline"}
                  mx="5px"
                />
                <Text w="200px">{TokenBname}</Text>
                <i
                  className={
                    selectedCoin2 === tokenNameB ? "fa-solid fa-check" : ""
                  }
                  style={{ marginLeft: "auto", color: "#00E3C9" }}
                ></i>
              </Flex>
              {menuItems.map((item, index) => (
                <Flex
                  align={"center"}
                  gap="20px"
                  bg="gray.100"
                  p="10px"
                  mt="10px"
                  borderRadius={"10px"}
                >
                  <Image
                    // src={selectedCoin2.imgSrc}
                    h="18px"
                    mb="-4px"
                    display={"inline"}
                    mx="5px"
                  />
                  <Text w="200px">{item?.text}</Text>
                  <i
                    className={
                      selectedCoin2 === item?.text ? "fa-solid fa-check" : ""
                    }
                    style={{ marginLeft: "auto", color: "#00E3C9" }}
                  ></i>
                </Flex>
              ))}
            </Box>
          </ModalBody>
        }
      />
    </Box>
  );
};

export default NewPosition;
