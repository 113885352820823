import { Box } from "@chakra-ui/react";
import SwapCard from "../components/swapCard";



const Swap = () => {
  return (
    <Box px='20px'>
      <SwapCard />
    </Box>
  );
};

export default Swap;
