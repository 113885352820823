import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  ModalBody,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  Divider,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuList,
  Image,
  Switch,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useContractRead, useContractWrite } from "wagmi";
import {
  SettingsIcon,
  ChevronDownIcon,
  ArrowDownIcon,
  Search2Icon,
} from "@chakra-ui/icons";
import CustomButton from "./customButton";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { parseUnits } from "viem";
import {
  contractData,
  fromWeiToDecimals,
  toWeiToDecimals,
} from "../utils/web3.util";
import { useAccount } from "wagmi";
import SwapModal from "./swapModal";
import SwapInput from "./swapInput";

export const menuItems = [
  { icon: "fa-brands fa-ethereum", imgSrc: "usdt.png", text: "USDT" },
];

const SwapCard = () => {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: recieveOpen,
    onOpen: onReceiveOpen,
    onClose: onReceiveClose,
  } = useDisclosure();
  const [cstInput, setCstInput] = useState("");
  const [tInput, setTInput] = useState("");
  const [tokenData, setTokenData]: any = useState([]);

  //filter
  const [searchInput, setSearchInput]: any = useState("");
  const [searchInput2, setSearchInput2]: any = useState("");
  const [tokenName, setTokenName] = useState([]);
  const [tokenNameB, setTokenNameB]: any = useState([]);
  const [inputValue, setInputValue]: any = useState(0);
  const [inputValue2, setInputValue2]: any = useState(0);

  const [isOpen2, setIsOpen2] = useState(false);
  // const [selectedCoin, setSelectedCoin] = useState([]);
  const [selectedCoin, setSelectedCoin]: any = useState();
  const [selectedCoin2, setSelectedCoin2]: any = useState();
  const [payDir, setPayDir] = useState(false);
  const [selectedOption, setSelectedOption] = useState(1);
  const [isSwap, setIsSwap] = useState(true);

  const value1 = toWeiToDecimals(inputValue);
  const convertvalue = BigInt(value1);

  console.log("input value 1", typeof convertvalue);

  console.log("bnb value", convertvalue);
  // console.log("valu3 string", typeof toWeiToDecimals(value1))

  const toggleSwap = () => {
    setIsSwap(!isSwap);
  };
  const togglePayDir = () => {
    setPayDir(!payDir);
  };

  //blockchain function

  // console.log("tokenData", tokenData);
  // console.log("selectedCoin1", selectedCoin);
  // console.log("selectedCoin2", selectedCoin2);
  // console.log("search input 1", searchInput);
  // console.log("search input 2", searchInput2);
  // console.log("token Name", tokenName);
  // console.log("inputValue1", inputValue)
  // console.log("input value 2", toWeiToDecimals(inputValue2));

  const { data: TokenAname }: any = useContractRead({
    // address: "0x7465757444AF17cF22Aa66880591D6aA72580A4c",
    address: searchInput,
    abi: contractData?.tokenAABI,
    functionName: "name",
  });

  const { data: TokenASymbol }: any = useContractRead({
    address: searchInput,
    abi: contractData?.tokenAABI,
    functionName: "symbol",
  });

  const { data: TokenBname }: any = useContractRead({
    address: searchInput2,
    abi: contractData?.tokenBABI,
    functionName: "name",
  });

  const { data: TokenBSymbol }: any = useContractRead({
    address: searchInput2,
    abi: contractData?.tokenBABI,
    functionName: "symbol",
  });

  const { data: getPair }: any = useContractRead({
    address: contractData?.linkdaoDexFactoryAddress,
    abi: contractData?.linkdaiDexFactoryABI,
    functionName: "getPair",
    args: [
      selectedCoin === "BNB" ? contractData?.weth9Address : searchInput,
      selectedCoin2 === "BNB" ? contractData?.weth9Address : searchInput2,
    ],
  });

  const { data: getReserves }: any = useContractRead({
    address: getPair,
    abi: contractData?.LinkdaoDexPairABI,
    functionName: "getReserves",
    select: (data) => {
      return {
        reserve1: data?.[0],
        reserve2: data?.[1],
        timestamp: data?.[2],
      };
    },
  });
  const { data: quote }: any = useContractRead({
    address: contractData?.linkdaoDexRouterAddress,
    abi: contractData?.linkdaoDexRouterABI,
    functionName: "quote",
    args: [
      toWeiToDecimals(inputValue),
      getReserves?.reserve1,
      getReserves?.reserve2,
    ],
  });

  console.log("quote", fromWeiToDecimals(quote));

  const quotee: any = fromWeiToDecimals(quote);

  const discount: any = quotee * 0.1;

  const adjustedQuote = quotee - discount;

  const { write: swapToken, isLoading: isSwapLoading }: any = useContractWrite({
    address: contractData?.linkdaoDexRouterAddress,
    abi: contractData?.linkdaoDexRouterABI,
    functionName: "swapExactTokensForTokens",

    args: [
      toWeiToDecimals(inputValue),
      toWeiToDecimals(quotee * 0.8),
      [searchInput, searchInput2],
      address,
      1761982697,
    ],
  });

  const {
    write: swapExactETHForTokens,
    isLoading: isSwapLoadingEthtoToken,
  }: any = useContractWrite({
    address: contractData?.linkdaoDexRouterAddress,
    abi: contractData?.linkdaoDexRouterABI,
    value: convertvalue,
    functionName: "swapExactETHForTokens",
    // value: toWeiToDecimals(inputValue),
    args: [
      toWeiToDecimals((inputValue2 * 0.6)?.toString(), 18),
      [searchInput, searchInput2],
      address,
      1761982697,
    ],
  });

  const {
    write: swapExactTokensForETH,
    isLoading: isSwapLoadingTokenToEth,
  }: any = useContractWrite({
    address: contractData?.linkdaoDexRouterAddress,
    abi: contractData?.linkdaoDexRouterABI,
    functionName: "swapExactTokensForETH",
    args: [
      toWeiToDecimals((inputValue * 0.6)?.toString(), 18),
      0,
      [searchInput, searchInput2],
      address,
      1761982697,
    ],
  });

  useEffect(() => {
    setTokenName(TokenAname);
  }, [searchInput]);

  useEffect(() => {
    setTokenNameB(TokenBname);
  }, [searchInput2]);

  useEffect(() => {
    setInputValue2(fromWeiToDecimals(quote));
  }, [quote]);

  // console.log("tokendata", tokenData);

  return (
    <Box
      bg="white"
      borderRadius={"15px"}
      border="1px solid #E2E8F0"
      w={{ sm: "464px" }}
      m="auto"
      p="20px 10px"
      mt={"100px"}
      textAlign={"left"}
      boxShadow={"1px 1px 1000px 100px #ECF6F6"}
    >
      <Flex justify={"space-between"} align="center" px="15px">
        <Flex gap="20px">
          <Text
            cursor={"pointer"}
            onClick={toggleSwap}
            color={isSwap ? "black" : ""}
          >
            Swap
          </Text>
        </Flex>
        <Menu>
          <MenuButton as={Button} variant={"unstyled"}>
            <SettingsIcon />
          </MenuButton>
          <MenuList mt={"0px"} ml="-265px" w="322px" zIndex={2} p="10px">
            {/* <MenuItem cursor={"auto"} _hover={{ bg: "none" }} display={"block"}> */}
            <Box>
              <Image display={"inline"} mr="5px" h="20px" src="logo192.png" />
              <Text fontSize={"20px"} color={"#40828D"} display={"inline"}>
                SwapDex
              </Text>
            </Box>
            <Flex justify={"space-between"} my="10px">
              <Text>
                When available, aggregates liquidity sources for better prices
                and gas free swaps.
              </Text>
              <Switch size="lg" />
            </Flex>
            <Accordion>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Max Slippage
                    </Box>
                    {selectedOption === 1 ? (
                      <Text>Auto</Text>
                    ) : (
                      <Text>{cstInput ? cstInput : "0"}%</Text>
                    )}

                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <ButtonGroup isAttached>
                    <Button
                      fontSize={"12px"}
                      colorScheme={selectedOption === 1 ? "teal" : "gray"}
                      onClick={() => setSelectedOption(1)}
                    >
                      Auto
                    </Button>
                    <Button
                      fontSize={"12px"}
                      colorScheme={selectedOption === 2 ? "teal" : "gray"}
                      onClick={() => setSelectedOption(2)}
                    >
                      Custom
                    </Button>
                  </ButtonGroup>
                  <Input
                    w="48%"
                    ml="8px"
                    value={cstInput}
                    onChange={(e) => setCstInput(e.target.value)}
                    isDisabled={selectedOption === 1}
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Transaction Deadline
                    </Box>
                    <Text>{tInput ? tInput : 10}m</Text>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Input
                    value={tInput}
                    type="number"
                    onChange={(e) => setTInput(e.target.value)}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            {/* </MenuItem> */}
          </MenuList>
        </Menu>
      </Flex>
      <Flex direction={"column"}>
        <Box
          order={payDir ? 3 : 1}
          mt={payDir ? "-17.5px" : "20px"}
          h="125px"
          bg="#F9F9F9"
          p="20px"
          borderRadius={"15px"}
        >
          {payDir ? <Text>You Receive</Text> : <Text>You Pay</Text>}
          <SwapInput
            bg="white"
            color="black"
            input={inputValue}
            setInput={setInputValue}
            onClick={onOpen}
            selectedCoin={selectedCoin}
          />
        </Box>
        <ArrowDownIcon
          order={2}
          bg="#F9F9F9"
          color={"black"}
          boxSize={"40px"}
          border="5px solid white"
          borderRadius={"10px"}
          mt="-17.5px"
          ml="calc(50% - 20px)"
          zIndex={"1"}
          onClick={togglePayDir}
          cursor={"pointer"}
        />
        <Box
          order={payDir ? 1 : 3}
          h="125px"
          bg="#F9F9F9"
          mt={payDir ? "20px" : "-17.5px"}
          p="20px"
          borderRadius={"15px"}
        >
          {!payDir ? <Text>You Receive</Text> : <Text>You Pay</Text>}
          <SwapInput
            bg="#00E3C9"
            color="white"
            input={inputValue2}
            setInput={setInputValue2}
            onClick={onReceiveOpen}
            selectedCoin={selectedCoin2}
          />
        </Box>
      </Flex>
      <CustomButton
        w="100%"
        mt="5px"
        borderRadius={"15px"}
        h="56px"
        text={isConnected ? "Swap" : "Connect Wallet"}
        onClick={() => {
          if (isConnected) {
            if (selectedCoin === "BNB") {
              swapExactETHForTokens();
            } else if (selectedCoin2 === "BNB") {
              swapExactTokensForETH();
            } else {
              swapToken();
            }
          } else {
            open();
          }
        }}
      />

      <SwapModal
        isOpen={isOpen ? isOpen : recieveOpen}
        onClose={isOpen ? onClose : onReceiveClose}
        setSearch={isOpen ? setSearchInput : setSearchInput2}
        setCoin={isOpen ? setSelectedCoin : setSelectedCoin2}
        tokenName={isOpen ? TokenAname : tokenNameB}
        selectedCoin={isOpen ? selectedCoin : selectedCoin2}
      />
    </Box>
  );
};

export default SwapCard;
