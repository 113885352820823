import {
  Box,
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React from "react";
import CustomButton from "../components/customButton";
import { Link } from "react-router-dom";
import PositionsCard from "../components/positionsCard";

const Pool = () => {
  return (
    <Box maxW="870px" m="auto" p="20px" fontFamily={"Kanit"}>
      <Flex align="center" justify={"space-between"} mt={"50px"}>
        <Heading
          fontFamily={"Kanit"}
          color="black"
          fontSize={{ base: "26px", sm: "32px" }}
        >
          Pools
        </Heading>
        <Box>
          <Menu>
            <MenuButton
              as={Button}
              _hover={{ bg: "transparent" }}
              bg="transparent"
              border="1px solid teal"
              color="black"
              fontSize={{ base: "14px", sm: "" }}
              p={{ base: "8px", sm: "" }}
              h={{ base: "30px", sm: "" }}
            >
              <Text mr="5px" display={"inline"}>
                More
              </Text>
              <i className="fa-solid fa-chevron-down"></i>
            </MenuButton>
            <MenuList>
              <MenuItem justifyContent={"space-between"}>
                Migrate <i className="fa-solid fa-angles-right"></i>
              </MenuItem>
              <MenuItem justifyContent={"space-between"}>
                V2 Liquidity <i className="fa-solid fa-database"></i>
              </MenuItem>
              <MenuItem justifyContent={"space-between"}>
                Learn <i className="fa-solid fa-book-open"></i>
              </MenuItem>
            </MenuList>
          </Menu>
          <Link to={"/new-position"}>
            <CustomButton
              text="+ New Position"
              mx={2}
              p={{ base: "8px", sm: "" }}
              h={{ base: "30px", sm: "" }}
            />
          </Link>
        </Box>
      </Flex>
      <PositionsCard />
      {/* <Box
          mt="30px"
          border="1px solid #E2E8F0"
          borderRadius={"15px"}
          py="40px"
        >
          <i
            className="fa-solid fa-wallet"
            style={{ fontSize: "48px", color: "#00E3C9" }}
          ></i>
          <Text my="20px" w="300px" mx="auto">
            Your active V3 liquidity positions will appear here.
          </Text>
          <CustomButton
            text="Connect Wallet"
            w="300px"
            h="42px"
            mt="5px"
            borderRadius={"15px"}
          />
        </Box> */}
      <Flex mt="20px" gap="20px" direction={{ base: "column", md: "row" }}>
        <Box
          border="1px solid #E2E8F0"
          borderRadius={"15px"}
          p="20px"
          textAlign={"left"}
          w="100%"
          cursor={"pointer"}
          _hover={{ opacity: "0.8" }}
        >
          <Text fontWeight={"700"} fontSize={"15px"}>
            Learn about providing liquidty{" "}
            <i className="fa-solid fa-arrow-trend-up"></i>
          </Text>
          <Text>Check out our v3 LP walkthrough and migration guides.</Text>
        </Box>
        <Box
          border="1px solid #E2E8F0"
          borderRadius={"15px"}
          p="20px"
          textAlign={"left"}
          w="100%"
          cursor={"pointer"}
          _hover={{ opacity: "0.8" }}
        >
          <Text fontWeight={"700"} fontSize={"15px"}>
            Top pools <i className="fa-solid fa-arrow-trend-up"></i>
          </Text>
          <Text>Explore Swapdex Analytics</Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Pool;
