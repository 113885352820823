import { Button, Spinner } from "@chakra-ui/react";
import React from "react";

const CustomButton = (props: any) => {
  const { text, borderRadius, w, h, mt, mx, display, p, onClick, loading } =
    props;
  return (
    <Button
      bg="#2B0763"
      colorScheme="teal"
      borderRadius={borderRadius ? borderRadius : "5px"}
      _hover={{ bg: "#2B0763" }}
      w={w}
      h={h}
      p={p}
      mt={mt}
      mx={mx}
      fontSize={{ base: "10px", sm: "14px", md: "" }}
      display={display}
      fontFamily={"Kanit"}
      onClick={props.onClick}
    >
      {loading ? <Spinner /> : text}
    </Button>
  );
};

export default CustomButton;
