import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
    semanticTokens: {
      colors: {
        error: 'red.500',
        text: {
          default: 'gray.900',
          _dark: 'gray.50',
        },
      },
      Button: {
        bg:'#00E3C9'
      }
    },
  })
  

export default customTheme;
