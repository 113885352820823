import { Badge, Box, Flex, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

const Marquee = () => {
  return (
    <Box
      whiteSpace="nowrap"
      overflow="hidden"
      position="relative"
      bg="transparent"
      color="white"
      fontSize="xl"
      fontFamily={"Kanit"}
      w="100%"
      p={2}
      my={{ base: "30px"}}
    >
      <motion.div
        animate={{ x: ["90vw", "-40vw"], y: 0 }}
        transition={{
          type: "tween",
          ease: "linear",
          duration: 10,
          repeat: Infinity,
        }}
      >
        <Flex gap="30px">
          <Badge
            px="25px"
            bg="#00E3C9"
            color={"white"}
            py="10px"
            borderRadius={"30px"} fontSize='16px'
          >
            <Text>
              <i className="fa-brands fa-ethereum"></i> Base
            </Text>
          </Badge>
          <Badge
            px="25px"
            bg="#AD73DE"
            color={"white"}
            py="10px"
            borderRadius={"30px"} fontSize='16px'
          >
            <Text>
              <i className="fa-brands fa-ethereum"></i> BNB
            </Text>
          </Badge>{" "}
          <Badge
            px="25px"
            bg="#686EA7"
            color={"white"}
            py="10px"
            borderRadius={"30px"} fontSize='16px'
          >
            <Text>
              <i className="fa-brands fa-ethereum"></i> Lieana
            </Text>
          </Badge>{" "}
          <Badge
            px="25px"
            bg="#D8A60A"
            color={"white"}
            py="10px"
            borderRadius={"30px"} fontSize='16px'
          >
            <Text>
              <i className="fa-brands fa-ethereum"></i> ETH
            </Text>
          </Badge>{" "}
          <Badge
            px="25px"
            bg="#74C2D2"
            color={"white"}
            py="10px"
            borderRadius={"30px"} fontSize='16px'
          >
            <Text>
              <i className="fa-brands fa-ethereum"></i> Base
            </Text>
          </Badge>
          <Badge
            px="25px"
            bg="#00E3C9"
            color={"white"}
            py="10px"
            borderRadius={"30px"} fontSize='16px'
          >
            <Text>
              <i className="fa-brands fa-ethereum"></i> Base
            </Text>
          </Badge>
          <Badge
            px="25px"
            bg="#AD73DE"
            color={"white"}
            py="10px"
            borderRadius={"30px"} fontSize='16px'
          >
            <Text>
              <i className="fa-brands fa-ethereum"></i> BNB
            </Text>
          </Badge>{" "}
          <Badge
            px="25px"
            bg="#686EA7"
            color={"white"}
            py="10px"
            borderRadius={"30px"} fontSize='16px'
          >
            <Text>
              <i className="fa-brands fa-ethereum"></i> Lieana
            </Text>
          </Badge>{" "}
          <Badge
            px="25px"
            bg="#D8A60A"
            color={"white"}
            py="10px"
            borderRadius={"30px"} fontSize='16px'
          >
            <Text>
              <i className="fa-brands fa-ethereum"></i> ETH
            </Text>
          </Badge>{" "}
          <Badge
            px="25px"
            bg="#74C2D2"
            color={"white"}
            py="10px"
            borderRadius={"30px"} fontSize='16px'
          >
            <Text>
              <i className="fa-brands fa-ethereum"></i> Base
            </Text>
          </Badge>

        </Flex>
      </motion.div>
    </Box>
  );
};

export default Marquee;
