import { Box, Button, ButtonGroup, Flex, Image, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";

const CustomCard = (props: any) => {
  const {
    title,
    des,
    btns,
    price,
    type,
    selectedCoin,
    selectedCoin2,
    updateIsHidden,
    borderColor,
    cursor,
    onClick,
    value,
    onChange,
  } = props;
  const [lowP, setLowP] = useState(0);
  const [highP, setHighP] = useState(0);
 
  const toggleVisibility = () => {
    updateIsHidden();
  };

  return (
    <Box
      p="20px"
      borderRadius={"15px"}
      my="10px"
      mx="auto"
      w={{ base: "100%" }}
      border="1px solid #E2E8F0"
      borderColor={borderColor}
      cursor={cursor}
      onClick={onClick}
    >
      <Flex justify={"space-between"} align={"center"}>
        <Box>
          <Text>{title}</Text>
          {price && (
            <Input
              placeholder={price}
              variant={"unstyled"}
              type="number"
              fontSize={"22px"}
              fontWeight={"bold"}
              isDisabled={btns?.length > 1}
              value={(() => {
                switch (type) {
                  case "lowP":
                    return lowP;
                  case "highP":
                    return highP;
                  case "depo1":
                    return value;
                  case "depo2":
                    return value;
                  default:
                    return 0;
                }
              })()}
              onChange={onChange}
            />
          )}
          <Text fontSize={"12px"} fontWeight={400}>
            {des}
          </Text>
        </Box>
        <ButtonGroup
          flexDirection={"column"}
          alignItems={"center"}
          gap="15px"
          w="max-content"
        >
          {btns?.map((btn: String, index: number) => (
            <Button
              w={btn==='Select Token'?'250px':"100%"}
              marginInlineStart={"0.5rem"}
              key={index}
              onClick={() => {
                if (btn === "Edit" || btn === "Hide") {
                  toggleVisibility();
                }
                if (type === "lowP") {
                  setLowP((prevLowP) =>
                    btn === "+" ? prevLowP + 1 : Math.max(0, prevLowP - 1)
                  );
                }
                if (title === "High price") {
                  setHighP((prevHighP) =>
                    btn === "+" ? prevHighP + 1 : Math.max(0, prevHighP - 1)
                  );
                }
              }}
            >
              {btn}
            </Button>
          ))}
        </ButtonGroup>
        {selectedCoin && (
          <Flex
            align={"center"}
            justify={"space-around"}
            gap="5px"
            h="35px"
            fontSize={"14px"}
            bg="transparent"
            color="black"
            border="1px solid #00E3C9"
            borderRadius={"20px"}
            // onClick={onOpen}
            cursor={"pointer"}
            px="10px"
          >
            {selectedCoin.imgSrc && <Image
                src={selectedCoin.imgSrc}
                h="18px"
                mb="-4px"
                display={"inline"}
                mx="5px"
              />}
            <Text>{selectedCoin}</Text>
          </Flex>
        )}
        {selectedCoin2 && (
          <Flex
            align={"center"}
            justify={"space-around"}
            px="10px"
            gap="5px"
            h="35px"
            fontSize={"14px"}
            color="white"
            bg="#00E3C9"
            borderRadius={"20px"}
            //   onClick={toggle}
            cursor={"pointer"}
          >
            {selectedCoin2.imgSrc && <Image
                src={selectedCoin2.imgSrc}
                h="18px"
                mb="-4px"
                display={"inline"}
                mx="5px"
              />}
            <Text whiteSpace={'nowrap'}>
              {selectedCoin2}
            </Text>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default CustomCard;
