import { Box, Divider, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

const Footer = ({ setFooterH }: any) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean-up function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (boxRef.current) {
      const height = boxRef.current.clientHeight + 80;
      setFooterH(height);
      console.log(height);
    }
  }, [windowSize]);

  return (
    <Box
      maxW="1212px"
      mx="auto"
      p="100px 30px 30px 30px"
      fontFamily={"Kanit"}
      color={"#2B0763"}
      fontSize={"14px"}
      mb={{ base: "50px", md: "0px" }}
      ref={boxRef}
    >
      <Box>
        <Box display={{ md: "none", base: "" }}>
          <Image
            maxW={{ base: "160px" }}
            mx="auto"
            mb="50px"
            src="img/SwapLogo.png"
          />
        </Box>
        <Flex justify="space-between" gap="60px" wrap={"wrap"}>
          <Box flexGrow={0} mx="auto" display={{ base: "none", md: "block" }}>
            <Image maxW={{ base: "190px" }} src="img/SwapLogo.png" />
          </Box>
          <Box flexGrow={1} textAlign={{ base: "center", sm: "left" }}>
            <Heading fontSize={"22px"} mb="20px">
              About Us
            </Heading>
            <a
              href="https://linkdao-network.gitbook.io/linkdao/protocol/roadmap"
              target="_blank"
            >
              <Text mb="10px">Roadmap</Text>
            </a>
            <a
              href="https://linkdao-network.gitbook.io/linkdao/development/security"
              target="_blank"
            >
              <Text mb="10px">Security</Text>
            </a>
            <a
              href="https://linkdao-network.gitbook.io/linkdao/protocol/tokenomics"
              target="_blank"
            >
              <Text mb="10px">Tokenomics</Text>
            </a>
            <a
              href="https://linkdao-network.gitbook.io/linkdao/contacts/contacts"
              target="_blank"
            >
              <Text mb="10px">Contact Us</Text>
            </a>
          </Box>
          <Box flexGrow={1} textAlign={{ base: "center", sm: "left" }}>
            <Heading fontSize={"22px"} mb="20px">
              Solutions
            </Heading>
            <a
              href="https://linkdao-network.gitbook.io/linkdao/introduction/staking-and-earning"
              target="_blank"
            >
              <Text mb="10px">Earn</Text>
            </a>
            <a href="https://app.linkdao.network/" target="_blank">
              <Text mb="10px">LKD Tools</Text>
            </a>
            <a href="https://linkdao.network/#/BuyLKD" target="_blank">
              <Text mb="10px">Buy LKD</Text>
            </a>
            <a
              href="https://linkdao-network.gitbook.io/linkdao/introduction/lets-get-started"
              target="_blank"
            >
              <Text mb="10px">LKD Token</Text>
            </a>
          </Box>
          <Box flexGrow={1} textAlign={{ base: "center", sm: "left" }}>
            <Heading fontSize={"22px"} mb="20px">
              Learn
            </Heading>
            <a href="https://linkdao.network/" target="_blank">
              <Text mb="10px">How to buy LKD</Text>
            </a>
            <a href="https://linkdao.network/" target="_blank">
              <Text mb="10px">How to Stake</Text>
            </a>
          </Box>
          <Box flexGrow={1} textAlign={{ base: "center", sm: "left" }}>
            <Heading fontSize={"22px"} mb="20px">
              Social
            </Heading>
            <Flex justify={{ base: "center", sm: "left" }} gap="20px">
              <a href="https://t.me/linkdao_network" target="_blank">
                <i className="fab fa-telegram fa-2x"></i>
              </a>
              <a target="_blank" href="https://twitter.com/LinkdaoN">
                <i className="fab fa-twitter fa-2x"></i>
              </a>
              <a href="https://medium.com/@linkdaonetwork" target="_blank">
                <i className="fab fa-medium fa-2x"></i>
              </a>
            </Flex>
            <Flex justify={{ base: "center", sm: "left" }} gap="20px" mt="20px">
              <a href="https://github.com/linkdaonetwork" target="_blank">
                <i className="fab fa-github fa-2x"></i>
              </a>
              <a
                href="https://linkdao-network.gitbook.io/linkdao"
                target="_blank"
              >
                <i className="fa fa-book fa-2x"></i>
              </a>
              <a href="mailto:contact@linkdao.network" target="_blank">
                <i className="fa fa-envelope fa-2x"></i>
              </a>
            </Flex>
          </Box>
        </Flex>
        <Divider mt="50px" mb="5px" mx="auto" />
        <Flex
          justify={{ base: "center", md: "space-between" }}
          align="center"
          wrap="wrap"
        >
          <Flex gap="10px" display={{ base: "flex", md: "none" }}>
            <a
              href="https://coinmarketcap.com/currencies/linkdao-network/"
              target="_blank"
            >
              <Image src="https://linkdao.network/static/media/coinMarketCap.5ba639eb0400acb0ad911140c7d768c8.svg" />
            </a>
            <a
              href="https://www.coingecko.com/en/coins/linkdao"
              target="_blank"
            >
              <Image src="https://linkdao.network/static/media/coinGecko.032c651f97ccfff8722c41e2918cae0e.svg" />
            </a>
            <a href="https://linkdao.network/#/Disclaimer" target="_blank">
              <Image src="https://linkdao.network/static/media/disclaimer.02b1b6198e9d2ef834f1bb8374337156.svg" />
            </a>
          </Flex>
          <Text
            fontSize={"12px"}
            textAlign="left"
            mt={{ base: "15px", md: "0" }}
          >
            © {new Date().getFullYear()} Linkswap | All Rights Reserved
          </Text>
          <Flex gap="10px" display={{ base: "none", md: "flex" }}>
            <a
              href="https://coinmarketcap.com/currencies/linkdao-network/"
              target="_blank"
            >
              <Image src="https://linkdao.network/static/media/coinMarketCap.5ba639eb0400acb0ad911140c7d768c8.svg" />
            </a>
            <a
              href="https://www.coingecko.com/en/coins/linkdao"
              target="_blank"
            >
              <Image src="https://linkdao.network/static/media/coinGecko.032c651f97ccfff8722c41e2918cae0e.svg" />
            </a>
            <a href="https://linkdao.network/#/Disclaimer" target="_blank">
              <Image src="https://linkdao.network/static/media/disclaimer.02b1b6198e9d2ef834f1bb8374337156.svg" />
            </a>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Footer;
