import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = () => {
  // Generate random y-axis data between 60 and 100
  //   const randomData = Array.from(
  //     { length: 131 },
  //     () => Math.floor(Math.random() * 41) + 60
  //   );

  //   const options: any = {
  //     chart: {
  //       id: "area-chart",
  //       type: 'bar',
  //   sparkline: {
  //     enabled: true
  //   },
  //       toolbar: {
  //         show: false, // Hide the default toolbar with pan and zoom controls
  //       },
  //       datapoints: {
  //         show: false,
  //       },
  //     },
  //     xaxis: {
  //       categories: Array.from({ length: 31 }, (_, i) => (i + 1).toString()),
  //       show: false,
  //       labels: {
  //         show:false
  //       }
  //     },
  // yaxis: {
  //   show: false, // Hide y-axis labels
  // },
  // grid: {
  //   show: false,
  // },
  //     // fill: {
  //     //   colors: ["#00E3C9", '#000'], // Set the area fill color
  //     //   type: "gradient", // Set the fill type to solid
  //     // //   opacity: 0.1,
  //     // },
  //     fill: {
  //         type: 'gradient',
  //         gradient: {
  //           shade: 'dark',
  //           type: "vertical",
  //           shadeIntensity: 1,
  //           gradientToColors: ["#00E3C9"], // optional, if not defined - uses the shades of same color in series
  //           inverseColors: false,
  //           opacityFrom: 1,
  //           opacityTo: 0.2,
  //           stops: [0, 50, 100],
  //           colorStops: []
  //         }
  //       },
  //     stroke: {
  //       curve: "smooth", // Use a smooth curve for the line
  //       colors: ["#00E3C9"], // Set the line color to #2C7A7B
  //       width: 1.2,
  //     },
  //     dataLabels: {
  //       style: {
  //         fontSize: "0px",
  //         fontWeight: "bold",
  //       },
  //     },
  //   };

  const options: any = {
    chart: {
      type: "bar",
      sparkline: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    yaxis: {
      show: false, // Hide y-axis labels
    },
    markers: {
      show: false,
    },
    // series: [{
    //   data: [{
    //     x: 'category A',
    //     y: 10
    //   }, {
    //     x: 'category B',
    //     y: 18
    //   }, {
    //     x: 'category C',
    //     y: 13
    //   }]
    // }]
  };

  const data = [];

  for (let i = 0; i < 86; i++) {
    const category = String.fromCharCode(65 + i); // A to Z
    const y = Math.floor(Math.random() * (50 - 10 + 1) + 10); // Random value between 10 and 50
    data.push({ x: `category ${category}`, y });
  }

  const series = [
    {
      data: data,
    },
  ];

  return (
    <Box
    border="1px solid #E2E8F0"
      maxW="588px"
      w={{ base: "100%" }}
      borderRadius={"15px"}
      p="15px"
    >
      <Text textAlign={"left"}>Volume 24H</Text>
      <Heading textAlign={"left"} color={"black"}>
        $443.08m
      </Heading>
      <Box mx="auto" maxW="520px" my='30px'>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={155}
        />
      </Box>
      <Flex maxW="520px" mx="auto" justify="space-between">
        {Array.from({ length: 16 }, (_, i) => (
          <Text key={i}>{(i + 1).toString()}</Text>
        ))}
      </Flex>
    </Box>
  );
};

export default BarChart;
