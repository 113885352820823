import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  ModalBody,
  Text,
} from "@chakra-ui/react";
import CustomModal from "./customModal";
import { contractData } from "../utils/web3.util";
import { menuItems } from "./swapCard";
import { Search2Icon } from "@chakra-ui/icons";

function SwapModal(props: any) {
  return (
    <Box>
      <CustomModal
        isOpen={props.isOpen}
        onClose={props.onClose}
        body={
          <ModalBody>
            <InputGroup size="md" h="42px" w={"100%"}>
              <InputLeftElement pointerEvents="none">
                <Search2Icon color="gray.300" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search name or paste address"
                borderRadius="15px"
                _focus={{ borderColor: "#00E3C9" }}
                onChange={(e) => props.setSearch(e.target.value)}
              />
            </InputGroup>
            <ButtonGroup py="20px" gap="4px" flexWrap={"wrap"}>
              <Button
                key="bnb"
                variant="solid"
                onClick={() => {
                  props.onClose();
                  props.setCoin("BNB"); // Update with your BNB selection logic
                  props.setSearch(contractData?.weth9Address);
                }}
              >
                BNB
              </Button>
              {menuItems.map((item: any, index: any) => (
                <Button
                  key={index}
                  variant="solid"
                  onClick={() => {
                    props.setCoin(props.tokenName);
                    props.onClose();
                  }}
                >
                  {props.tokenName}
                </Button>
              ))}
            </ButtonGroup>
            <Divider />
            {props.tokenName && (
              <Box h="443px" mt="10px">
                <Flex
                  align={"center"}
                  gap="20px"
                  bg="gray.100"
                  p="10px"
                  borderRadius={"10px"}
                  cursor="pointer"
                  onClick={() => {
                    props.setCoin(props.tokenName);
                    props.onClose();
                  }}
                >
                  <Image
                    // src={selectedCoin.imgSrc}
                    h="18px"
                    mb="-4px"
                    display={"inline"}
                    mr="5px"
                  />
                  <Text>{props.tokenName}</Text>
                  {props.selectedCoin === props.tokenName && (
                    <i
                      className="fa-solid fa-check"
                      style={{ marginLeft: "auto", color: "#00E3C9" }}
                    ></i>
                  )}
                </Flex>
              </Box>
            )}
          </ModalBody>
        }
      />
    </Box>
  );
}

export default SwapModal;
