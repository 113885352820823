import {
  Box,
  Divider,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";

const PositionsCard = () => {

  return (
    <Box borderRadius={"15px"} border="1px solid #E2E8F0" py="15px" mt="30px">
      <Flex justify={"space-between"} px="15px" fontSize={{base:'12px', sm:''}}>
        <Text>Your positions (1)</Text>
        <Text color={"#00E3C9"}>Hide closed positions</Text>
      </Flex>
      <Divider my="20px" />
      <Link to="/position-detail">
        <Box px="15px" cursor={"pointer"}>
          <Flex justify={"space-between"} align={"center"} wrap='wrap'>
            <Flex gap={{ base: "8px", sm: "15px" }} align="center">
              <Flex mr="-5px">
                <Image src="ether.png" w="15px" />
                <Image src="usdt.png" ml="-3px" w="15px" />
              </Flex>
              <Text color="black" fontSize={"18px"}>
                AST/MATIC
              </Text>
              <Text>0.01%</Text>
            </Flex>
            <Text color="red" whiteSpace={'nowrap'}>
              Out of range <i className="fa-solid fa-triangle-exclamation"></i>
            </Text>
          </Flex>
          <Text textAlign={"left"} color={"black"}>
            <span style={{ color: "grey" }}>Min: </span>2.00 AST per MATIC{" "}
            <i className="fa-solid fa-arrows-left-right"></i>{" "}
            <span style={{ color: "grey" }}>Max: </span>3.00 AST per MATIC
          </Text>
        </Box>
      </Link>
    </Box>
  );
};

export default PositionsCard;
