import * as React from "react";
import { ChakraProvider, Box } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Swap from "./pages/swap";
import Pool from "./pages/pool";
import Info from "./pages/info";
import customTheme from "./theme";
import NewPosition from "./pages/newPosition";
import PositionDetail from "./pages/positionDetail";
import Navbar from "./components/navbar";
import Home from "./pages/home";
import Footer from "./components/footer";

export const App = () => {
  const [footerH, setFooterH] = React.useState(0);
  return (
    <ChakraProvider theme={customTheme}>
      <Box
        textAlign="center"
        fontSize={"14px"}
        fontFamily={"Kanit"}
        fontWeight={"500"}
        color="grey"
        bgImage={"url(img/bg.png)"}
        bgSize={"100%"}
        minH={"100vh"}
      >
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home footerH={footerH} />} />
            <Route path="/swap" element={<Swap />} />
            <Route path="/pool" element={<Pool />} />
            <Route path="/info" element={<Info />} />
            <Route path="/new-position" element={<NewPosition />} />
            <Route path="/position-detail" element={<PositionDetail />} />
          </Routes>
          <Footer setFooterH={setFooterH} />
        </BrowserRouter>
      </Box>
    </ChakraProvider>
  );
};
