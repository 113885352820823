import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Marquee from "../components/marquee";
import DiscoverCard from "../components/discoverCard";
import Banners from "../components/banner";

const Home = ({ footerH }: any) => {
  const [pageH, setPageH] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const CustomHeading = ({ content, color, my, fontSize }: any) => {
    return (
      <Heading
        my={my}
        color={color || "#2B0763"}
        fontFamily={"Kanit"}
        fontSize={fontSize}
      >
        {content}
      </Heading>
    );
  };
  const CustomButton = ({
    text,
    color,
    bg,
    border,
    w,
    borderRadius,
    p,
  }: any) => {
    return (
      <Button
        w={w}
        variant={"unstyled"}
        border={border}
        bg={bg || "#2B0763"}
        color={color || "white"}
        p={p || "16px"}
        fontFamily={"Kanit"}
        fontSize={"16px"}
        h="auto"
        borderRadius={borderRadius || "8px"}
      >
        {text}
      </Button>
    );
  };

  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean-up function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (boxRef.current && footerH !== 0) {
      const height = boxRef.current.clientHeight + footerH;
      setPageH(height);
      console.log(footerH);
    }
  }, [windowSize, footerH]);

  return (
    <Box
      p={{ base: "35px 10px 0px 10px", md: "50px 50px 0px 50px" }}
      textAlign={"left"}
      fontFamily={"Kanit"}
      color={"#2B0763"}
      fontSize={"24px"}
      ref={boxRef}
    >
      <Box
        bg={"black"}
        opacity={"0.05"}
        position={"absolute"}
        top={"-10px"}
        left={"0px"}
        zIndex={"0"}
        w="100%"
        h={"100%"}
      />
      <Box zIndex={"60"} position={"relative"}>
        <Box maxW="1168px" mx="auto">
          <Banners />
        </Box>
        <Flex
          justify={"center"}
          gap="60px"
          align={"center"}
          direction={{ base: "column", lg: "row" }}
          my={{ base: "30px", md: "120px" }}
          maxW="1168px"
          mx="auto"
        >
          <Box justifyContent={"left"} order={{ base: "2", lg: "1" }}>
            <Heading
              color={"#2B0763"}
              fontFamily={"Kanit"}
              textAlign="left"
              fontSize={"56px"}
              display={{ base: "none", md: "inline" }}
            >
              Everyone’s <br /> Favorite DEX
            </Heading>
            <Heading
              color={"#2B0763"}
              fontFamily={"Kanit"}
              fontSize={"32px"}
              textAlign="center"
              display={{ base: "block", md: "none" }}
            >
              Everyone’s Favorite <br /> DEX
            </Heading>
            <Text textAlign={{ base: "center", md: "left" }}>
              Trade, earn and own crypto on the all-in-one multichain DEX
            </Text>
            <ButtonGroup
              gap="10px"
              width="100%"
              flexWrap="wrap"
              justifyContent={{ base: "center", md: "left" }}
              mt="25px"
            >
              <CustomButton
                w={{ base: "auto", md: "auto" }}
                text="Connect Wallet"
                color="white"
                borderRadius="32px"
                p="10px 20px"
              />
              <CustomButton
                w={{ base: "auto", md: "auto" }}
                bg="transparent"
                text="Trade Now"
                color="#2B0763"
                border="1px solid #2B0763"
                borderRadius="32px"
                p="10px 20px"
              />
            </ButtonGroup>
          </Box>
          <Box order={{ base: "1", lg: "2" }}>
            <Image
              borderRadius={"25px"}
              maxW="800px"
              w="100%"
              src="img/2.gif"
            />
          </Box>
        </Flex>
        <Box textAlign={"center"} maxW="1168px" mx="auto">
          {/* <Box>
          <CustomHeading
            fontSize="32px"
            content="Shaping the Future of Decentralized Trading: PancakeSwap’s Unstoppable Expansion"
          />
        </Box> */}
          {/* <Flex py="50px" justify={"center"} gap={'50px'} wrap={'wrap'} align={'center'}>
          <Box>
            <Text>Total Users:</Text>
            <CustomHeading content="1,027,377" my="10px" />
            <Text fontSize={"16px"}>in the last 30 days</Text>
          </Box>
          <Box>
            <Text>Total Traders:</Text>
            <CustomHeading content="14,0241,112" my="10px" />
            <Text fontSize={"16px"}>in the last 30 days</Text>
          </Box>
          <Box>
            <Text>Total Value Locked:</Text>
            <CustomHeading content="$1,299,987" my="10px" />
            <Text fontSize={"16px"}>in the last 30 days</Text>
          </Box>
        </Flex> */}
          <Box
            border={"1px solid #2B0763"}
            borderRadius={"15px"}
            mt={{ base: "50px", md: "0px" }}
            py="50px"
          >
            <Box textAlign={"center"} px="40px" mb="120px">
              <CustomHeading
                fontSize={"24px"}
                content="Shaping the Future of Decentralized Trading:"
              />
              <CustomHeading
                fontSize={"24px"}
                content="LinkSwap’s Unstoppable Expansion"
              />
            </Box>
            <Flex align={"center"} gap="80px">
              <Box
                bgGradient="linear-gradient(166deg, #2B0763 18.48%, #5E4585 104%)"
                w={{ base: "30px", sm: "100px" }}
                h="218px"
              />
              <Box>
                <Text>Total Users:</Text>
                <CustomHeading content="1,027,377" my="10px" />
                <Text fontSize={"16px"}>in the last 30 days</Text>
              </Box>
            </Flex>
            <Flex align={"center"} gap="80px" justify={"right"}>
              <Box>
                <Text>Total Traders:</Text>
                <CustomHeading content="14,0241,112" my="10px" />
                <Text fontSize={"16px"}>in the last 30 days</Text>
              </Box>
              <Box
                bgGradient="linear-gradient(166deg, #2B0763 18.48%, #5E4585 104%)"
                w={{ base: "30px", sm: "100px" }}
                h="218px"
              />
            </Flex>
            <Flex align={"center"} gap="80px">
              <Box
                bgGradient="linear-gradient(166deg, #2B0763 18.48%, #5E4585 104%)"
                w={{ base: "30px", sm: "100px" }}
                h="218px"
              />
              <Box>
                <Text>Total Value Locked:</Text>
                <CustomHeading content="$1,299,987" my="10px" />
                <Text fontSize={"16px"}>in the last 30 days</Text>
              </Box>
            </Flex>
          </Box>
        </Box>
        <Marquee />
        <Box textAlign={"center"} pt="100px" maxW="1168px" mx="auto">
          <CustomHeading
            fontSize="32px"
            my="100px"
            content="Discover the Ecosystem"
          />
          <Flex
            bg="white"
            borderRadius={"15px"}
            p={{ base: "30px 5px", lg: "30px" }}
            gap={"50px"}
            align="center"
            wrap="wrap"
          >
            <Image
              w="90%"
              maxW="340px"
              maxH="340px"
              src="img/trade-main.png"
              mx="auto"
            />
            <Box mx="auto">
              <Box textAlign="left" mb="30px">
                <CustomHeading content="Trade" />
              </Box>
              <Flex
                justify={{ base: "center", md: "space-between" }}
                gap="20px"
                wrap={"wrap"}
              >
                <DiscoverCard
                  title="Swap"
                  text="Trade Crypto instantly across multiple chains"
                  bg="swap"
                />
                <DiscoverCard
                  title="Liquidity"
                  text="Trade Crypto instantly across multiple chains"
                  bg="liquidity"
                />
                <DiscoverCard
                  title="Bridge"
                  text="Trade Crypto instantly across multiple chains"
                  bg="Bridge"
                />
                <DiscoverCard
                  title="Buy Crypto"
                  text="Trade Crypto instantly across multiple chains"
                  bg="buy"
                />
              </Flex>
            </Box>
          </Flex>
          <Flex
            bg="white"
            borderRadius={"15px"}
            p={{ base: "30px 5px", lg: "30px" }}
            gap={"50px"}
            align="center"
            wrap="wrap"
            my="30px"
          >
            <Image
              w="90%"
              maxW="340px"
              maxH="340px"
              src="img/game-main.png"
              mx="auto"
            />
            <Box mx="auto">
              <Box textAlign="left" mb="30px">
                <CustomHeading content="Game & NFT" />
              </Box>
              <Flex
                justify={{ base: "center", md: "space-between" }}
                gap="20px"
                wrap={"wrap"}
              >
                <DiscoverCard
                  title="Prediction"
                  text="Trade Crypto instantly across multiple chains"
                  bg="swap"
                />
                <DiscoverCard
                  title="Lottery"
                  text="Trade Crypto instantly across multiple chains"
                  bg="liquidity"
                />
                <DiscoverCard
                  title="Pottery"
                  text="Trade Crypto instantly across multiple chains"
                  bg="Bridge"
                />
                <DiscoverCard
                  title="NFT"
                  text="Trade Crypto instantly across multiple chains"
                  bg="buy"
                />
              </Flex>
            </Box>
          </Flex>
        </Box>
        <Box pt="280px" pb="100px" textAlign="center" maxW="1168px" mx="auto">
          <CustomHeading
            my="30px"
            content="Unlock the Full Potential of Linkdao DeFi with LinkSwap"
          />
          <Text maxW="750px" mx="auto" fontSize={"20px"}>
            Experience the power of community ownership, global governance, and
            explore infinite use cases within the LinkSwap ecosystem
          </Text>
          <ButtonGroup
            gap="10px"
            flexWrap="wrap"
            justifyContent={{ base: "center", sm: "" }}
            mt="38px"
          >
            <CustomButton
              w={{ base: "100%", sm: "auto" }}
              text="Buy LKD"
              color="white"
            />
            <CustomButton
              w={{ base: "100%", sm: "auto" }}
              bg="transparent"
              text="Learn More"
              color="#2B0763"
              border="1px solid #2B0763"
            />
          </ButtonGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
