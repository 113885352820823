import React, { useState } from "react";
import { Box, Flex, Button, Image, Text } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { useWeb3Modal } from "@web3modal/wagmi/react";

import "../assets/styles/nav.css";
import { useAccount } from "wagmi";

const Navbar: React.FC = () => {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();

  const [selectedCoin] = useState({
    icon: "fa-brands fa-ethereum",
    imgSrc: "ether.png",
    text: "BSC",
  });
  // const [isActive, setIsActive] = useState();

  const location = useLocation();

  const isNavLinkActive = (path: any) => {
    return location.pathname === path ? "nav-bar-active" : "";
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      paddingY="0.5rem"
      px={{ base: "15px", sm: "1rem" }}
      py="0px"
      fontSize={"14px"}
      fontFamily={"Kanit"}
      fontWeight={"600"}
      color="grey"
      position={"sticky"}
      top="0"
      zIndex={"200"}
      bg="#fff"
      h="56px"
    >
      <Flex align="center">
        <NavLink to="/">
          <Image
            src="img/SwapLogo.png"
            h={{ base: "15px", md: "20px" }}
            display={{ base: "none", md: "inline" }}
          />
          <Image
            src="img/SwapIcon.png"
            h={{ base: "32px", md: "32px" }}
            display={{ base: "inline", md: "none" }}
          />
        </NavLink>
        <Box
          display={{ base: "none", md: "inline" }}
          ml={{ md: "20px", lg: "40px", xl: "150px", "2xl": "300px" }}
          cursor={"pointer"}
        >
          <NavLink
            to={"/swap"}
            className={`nav-bar-link ${isNavLinkActive("/swap")}`}
          >
            {/* {isNavLinkActive("/") && (
            )}  */}
            {/* <i className="fa-solid fa-rotate"></i> Swap */}
            Swap
          </NavLink>
        </Box>
        <Box
          display={{ base: "none", md: "inline" }}
          ml={{ md: "20px", lg: "40px" }}
          cursor={"pointer"}
        >
          <NavLink
            to={"/new-position"}
            className={`nav-bar-link ${isNavLinkActive("/new-position")}`}
          >
            {/* {isNavLinkActive("/new-position") && (
              )}  */}
            {/* <i className="fa-solid fa-sack-dollar"></i> Liquidity */}
            Liquidity
          </NavLink>
        </Box>
        <Box
          display={{ base: "none", md: "inline" }}
          ml={{ md: "20px", lg: "40px" }}
          cursor={"pointer"}
        >
          <NavLink
            to={"/pool"}
            className={`nav-bar-link ${isNavLinkActive("/pool")}`}
          >
            {/* {isNavLinkActive("/pool") && (
              )}  */}
            {/* <i className="fa-solid fa-water"></i> Pool */}
            Pool
          </NavLink>
        </Box>
        <Box
          display={{ base: "none", md: "inline" }}
          ml={{ md: "20px", lg: "40px" }}
          cursor={"pointer"}
        >
          <NavLink to={"/"} className={`nav-bar-link`}>
            {/* {isNavLinkActive("/pool") && (
              )}  */}
            {/* <i className="fa-solid fa-water"></i> Pool */}
            Buy
          </NavLink>
        </Box>
        <Box
          display={{ base: "none", md: "inline" }}
          ml={{ md: "20px", lg: "40px" }}
          cursor={"pointer"}
        >
          <NavLink
            to={"/info"}
            className={`nav-bar-link ${isNavLinkActive("/info")}`}
          >
            {/* {isNavLinkActive("/info") && (
              )}  */}
            {/* <i className="fa-solid fa-circle-info"></i> Info */}
            Info
          </NavLink>
        </Box>
      </Flex>
      <Box>
        <Flex alignItems="center">
          <Image
            src={selectedCoin.imgSrc}
            h={{ base: "32px" }}
            display={"inline"}
            mr="5px"
          />
          <Button
            onClick={() => open()}
            fontSize={{ base: "16px" }}
            bg="#2B0763"
            color="#fff"
            borderRadius="24px"
            h="32px"
            // w='96.27px'
            // p={{ base: "0px 18px", md: "0px 15px" }}
          >
            {isConnected
              ? address?.replace(address?.slice(6, 37), "***")
              : "Connect"}
          </Button>
        </Flex>
      </Box>
      <Flex
        display={{ base: "flex", md: "none" }}
        pos="fixed"
        bottom="0"
        left="0"
        justify="space-between"
        align="center"
        width="100%"
        bg="#fff"
        padding="10px 30px"
        borderRadius="10px 10px 0px 0px"
      >
        <Box cursor={"pointer"}>
          <NavLink
            to={"/swap"}
            className={`nav-bar-link ${isNavLinkActive("/swap")}`}
          >
            <i className="fa-solid fa-rotate fa-2x"></i>
            <Text>Swap</Text>
          </NavLink>
        </Box>
        <Box cursor={"pointer"}>
          <NavLink
            to={"/new-position"}
            className={`nav-bar-link ${isNavLinkActive("/new-position")}`}
          >
            <i className="fa-solid fa-sack-dollar fa-2x"></i>
            <Text>Liquidity</Text>
          </NavLink>
        </Box>
        <Box cursor={"pointer"}>
          <NavLink
            to={"/pool"}
            className={`nav-bar-link ${isNavLinkActive("/pool")}`}
          >
            <i className="fa-solid fa-hourglass-end fa-2x"></i>
            <Text>Pool</Text>
          </NavLink>
        </Box>
        <Box cursor={"pointer"}>
          <NavLink to={"/"} className={`nav-bar-link`}>
            <i className="fa-solid fa-credit-card fa-2x"></i>
            <Text>Buy</Text>
          </NavLink>
        </Box>
        <Box cursor={"pointer"}>
          <NavLink
            to={"/info"}
            className={`nav-bar-link ${isNavLinkActive("/info")}`}
          >
            <i className="fa-solid fa-circle-info fa-2x"></i>
            <Text>Info</Text>
          </NavLink>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Navbar;
