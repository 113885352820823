import { GridItem } from "@chakra-ui/react";
import React from "react";

const CustomGridItem = (props: any) => {
  const { textAlign, content, colSpan, rowSpan, bg, bgSize } = props;
  return (
    <GridItem
      textAlign={textAlign}
      p="15px"
      borderRadius={"15px"}
      border="1px solid #E2E8F0"
      colSpan={colSpan}
      rowSpan={rowSpan}
      bg={bg}
      bgSize={bgSize}
    >
      {content}
    </GridItem>
  );
};

export default CustomGridItem;
