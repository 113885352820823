import { Box, Button,  Heading, Image, Text } from "@chakra-ui/react";
import React from "react";

const DiscoverCard = ({ bg, title, text }: any) => {
  const CustomHeading = ({ content, color, my, fontSize }: any) => {
    return (
      <Heading
        my={my}
        color={color || "#2B0763"}
        fontFamily={"Kanit"}
        fontSize={fontSize}
      >
        {content}
      </Heading>
    );
  };
  return (
    <Box flexGrow={1} textAlign={"left"}  maxW="125px">
        <Image src='img/trade.png' />
        <Box my="30px">
          <CustomHeading fontSize="20px" content={title} />
          <Text mt="10px" fontSize={"14px"}>
            {text}
          </Text>
        </Box>
        <Button w="100%" variant={"unstyled"} textAlign={"left"}>
          Trade Now <i className="fa-solid fa-angle-right"></i>
        </Button>
    </Box>
  );
};

export default DiscoverCard;
