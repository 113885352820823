// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";

type Props = {};

const Banners = (props: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <Swiper
      className="swiper-wrapper"
      modules={[Pagination, Autoplay]}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
    >
      <SwiperSlide>
        {windowWidth < 768 ? (
          <Image
            src="img/mobile-banner2.jpg"
            alt="banner"
            className="img-fluid"
          />
        ) : (
          <Image src="img/Test.jpg" alt="banner" className="img-fluid" />
        )}
      </SwiperSlide>
    </Swiper>
  );
};

export default Banners;
