import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  Grid,
  GridItem,
  Heading,
  ButtonGroup,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomButton from "../components/customButton";
import CustomGridItem from "../components/customGridItem";
import { Link } from "react-router-dom";

const PositionDetail = () => {
  const [selectedOption, setSelectedOption] = useState(2);

  const CustomHeading = (props: any) => {
    const { t, my } = props;
    return (
      <Heading my={my} fontSize={"18px"}>
        {t}
      </Heading>
    );
  };
  const SmallCard = (props: any) => {
    const { t, my } = props;
    return (
      <Text
        py="5px"
        px="20px"
        borderRadius={"15px"}
        border="1px solid #E2E8F0"
        bg="#F9F9F9"
        w="fit-content"
        my={my}
      >
        {t}
      </Text>
    );
  };
  return (
    <Box px='15px'>
      <Box mx="auto" maxW="800px" p="80px 5px" mt={'0px'}>
        <Link to='/pool'>
          <Box textAlign={"left"} mb="20px">
            <Text cursor={"pointer"} fontSize={"14px"}>
              {" "}
              <i className="fa-solid fa-arrow-left"></i> Back to Pools
            </Text>
          </Box>
        </Link>
        <Flex justify={"space-between"} align={"center"} wrap="wrap" gap="15px">
          <Flex gap={{ base: "8px", sm: "15px" }} align="center" wrap="wrap">
            <Flex mr="-5px">
              <Image src="ether.png" w="15px" />
              <Image src="usdt.png" ml="-3px" w="15px" />
            </Flex>
            <Text color="black" fontSize={"18px"}>
              AST/MATIC
            </Text>
            <Text>0.01%</Text>
            <Text color="red">
              Out of range <i className="fa-solid fa-triangle-exclamation"></i>
            </Text>
          </Flex>
          <Flex gap="5px" wrap={"wrap"}>
            <Button colorScheme="teal" variant={"outline"} fontSize={"14px"}>
              Increase liquidity
            </Button>
            <CustomButton text="Remove liquidity" />
          </Flex>
        </Flex>
        <Grid
          pt={"20px"}
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(5, 1fr)"
          gap={3}
        >
          <CustomGridItem
            // bg="rgb(230, 255, 250, 0.1)"
            bg='url(img/card-bg.jpg)'
            bgSize={'cover'}
            rowSpan={2}
            colSpan={{ base: 5, md: 2 }}
            content={
              <>
                <Box p="15px" borderRadius={"15px"} border="1px solid #E2E8F0" textAlign={'left'}>
                  <Heading fontWeight={"normal"} color={'black'}>AST/MATIC</Heading>
                  <Heading fontWeight={"normal"} color={'black'}>0.01%</Heading>
                  <Box mt="230px">
                    <SmallCard t="ID: 12345678" />
                    <SmallCard my="10px" t="Min Tick: -123450" />
                    <SmallCard t="Max Tick: -1234" />
                  </Box>
                </Box>
              </>
            }
          />
          <CustomGridItem
            textAlign={"left"}
            colSpan={{ base: 5, md: 3 }}
            content={
              <>
                <Text>Liquidity</Text>
                <Heading my="20px">$-</Heading>
                <Box p="15px" borderRadius={"15px"} border="1px solid #E2E8F0">
                  <Flex justify="space-between">
                    <Flex gap={"10px"} align={"center"}>
                      <Image src="usdt.png" h="18px" />
                      <Text>AST</Text>
                    </Flex>
                    <Flex gap={"15px"} align={"center"}>
                      <Text>999900</Text>
                      <Text>100%</Text>
                    </Flex>
                  </Flex>
                  <Flex justify="space-between" mt="20px">
                    <Flex gap={"10px"} align={"center"}>
                      <Image src="ether.png" h="18px" />
                      <Text>MATIC</Text>
                    </Flex>
                    <Flex gap={"15px"} align={"center"}>
                      <Text>0</Text>
                      <Text>0%</Text>
                    </Flex>
                  </Flex>
                </Box>
              </>
            }
          />
          <CustomGridItem
            textAlign={"left"}
            colSpan={{ base: 5, md: 3 }}
            content={
              <>
                <Text>Unclaimed fees</Text>
                <Heading my="20px">$-</Heading>
                <Box p="15px" borderRadius={"15px"} border="1px solid #E2E8F0">
                  <Flex justify="space-between">
                    <Flex gap={"10px"} align={"center"}>
                      <Image src="usdt.png" h="18px" />
                      <Text>AST</Text>
                    </Flex>
                    <Flex gap={"15px"} align={"center"}>
                      <Text>0</Text>
                    </Flex>
                  </Flex>
                  <Flex justify="space-between" mt="20px">
                    <Flex gap={"10px"} align={"center"}>
                      <Image src="ether.png" h="18px" />
                      <Text>MATIC</Text>
                    </Flex>
                    <Flex gap={"15px"} align={"center"}>
                      <Text>0</Text>
                    </Flex>
                  </Flex>
                </Box>
              </>
            }
          />
        </Grid>
        <Box p="15px" borderRadius={"15px"} border="1px solid #E2E8F0" mt={3}>
          <Flex justify={"space-between"} align={"center"}>
            <Flex gap={3} wrap="wrap">
              <Text fontWeight={"medium"}>Price range</Text>
              <Text color="red">
                Out of range{" "}
                <i className="fa-solid fa-triangle-exclamation"></i>
              </Text>
            </Flex>
            <ButtonGroup isAttached>
              <Button
                fontSize={"12px"}
                p="5px"
                h="17px"
                colorScheme={selectedOption === 1 ? "teal" : "gray"}
                onClick={() => setSelectedOption(1)}
              >
                AST
              </Button>
              <Button
                fontSize={"12px"}
                p="5px"
                h="17px"
                colorScheme={selectedOption === 2 ? "teal" : "gray"}
                onClick={() => setSelectedOption(2)}
              >
                MATIC
              </Button>
            </ButtonGroup>
          </Flex>
          <Grid
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(21, 1fr)"
            gap={3}
            mt={2}
            textAlign={"center"}
          >
            <CustomGridItem
              colSpan={10}
              content={
                <>
                  <Text>Min price</Text>
                  <CustomHeading my="25px" t="2.0004" />
                  <Text>AST per MATIC</Text>
                </>
              }
            />
            <GridItem
              colSpan={1}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <i className="fa-solid fa-arrows-left-right"></i>
            </GridItem>
            <CustomGridItem
              colSpan={10}
              content={
                <>
                  <Text>Max price</Text>
                  <CustomHeading my="25px" t="3.0007" />
                  <Text>AST per MATIC</Text>
                </>
              }
            />
            <CustomGridItem
              colSpan={21}
              content={
                <>
                  <Text>Current price</Text>
                  <CustomHeading my="25px" t="50,0004.00" />
                  <Text>AST per MATIC</Text>
                </>
              }
            />
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default PositionDetail;
