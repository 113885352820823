import { ChevronDownIcon } from "@chakra-ui/icons";
import { Flex, Image, Input, Text } from "@chakra-ui/react";

function SwapInput(props: any) {
  return (
    <Flex align="center" justify={"space-between"}>
      <Input
        border="none"
        color="#888080"
        w="fit-content"
        fontSize={"32px"}
        fontWeight={"bold"}
        bg="transparent"
        placeholder="0"
        variant="unstyled"
        value={props.input}
        onChange={(e: any) => props.setInput(e.target.value)}
      />
      <Flex
        align={"center"}
        justify={"space-around"}
        gap="5px"
        h="36px"
        bg={props.bg}
        color={props.color}
        borderRadius={"20px"}
        onClick={props.onClick}
        cursor={"pointer"}
        px="10px"
      >
        <Image h="18px" mb="-4px" display={"inline"} mx="5px" />
        <Text whiteSpace="nowrap">{props.selectedCoin || "Select Token"}</Text>
        <ChevronDownIcon />
      </Flex>
    </Flex>
  );
}

export default SwapInput;
