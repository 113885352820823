import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Text,
  Box,
  Image,
} from "@chakra-ui/react";

const customScrollbarStyles = {
  "&::-webkit-scrollbar": {
    height: "3px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#00E3C9", // Set the color of the scrollbar thumb
    borderRadius: "1px", // Adjust the border radius of the thumb
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#00E3C9", // Change the color on hover
  },
  "&::-webkit-scrollbar-track": {
    background: "white", // Set the color of the scrollbar track
  },
};

const CustomTable = ({ data, headers }: any) => {
  return (
    <Box mt="40px" overflowX={"auto"} css={customScrollbarStyles}>
      <Flex justify={"space-between"} my="10px" color="#BEC0C6" w='1190px'>
        <Text color='gray.500'>{data[0].title}</Text>
        <Text color='gray.900'>Explore</Text>
      </Flex>
      <Table variant="simple" border="1px solid #E2E8F0" borderRadius={'15px'} w='1190px'>
        <Thead>
          <Tr>
            {headers.map((header: any, index: any) => (
              <Th key={index}>{header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item: any, index: any) => (
            <Tr key={index}>
              {headers[0]==='#'&&<Td>{index + 1}</Td>}
              {item.name && (
                <Td display={"flex"} alignItems={"center"}>
                  {item.name.imgSrc && (
                    <Flex mr="10px">
                      {item.name.imgSrc.map((src: any, imgIndex: any) => (
                        <Image key={imgIndex} src={src} h="14px" />
                      ))}
                    </Flex>
                  )}
                  <Box>{item.name.name}</Box>
                </Td>
              )}
              {item.price && <Td>{item.price}</Td>}
              {item.priceChange && (
                <Td color={item.priceChange.startsWith("+") ? "green" : "red"}>
                  {item.priceChange}
                </Td>
              )}
              {item.volume24h && <Td>{item.volume24h}</Td>}
              {item.tvl && <Td>{item.tvl}</Td>}
              {item.volume7D && <Td>{item.volume7D}</Td>}
              {item.totalValue && <Td>{item.totalValue}</Td>}
              {item.tokenAmount1 && <Td>{item.tokenAmount1}</Td>}
              {item.tokenAmount2 && <Td>{item.tokenAmount2}</Td>}
              {item.account && <Td>{item.account}</Td>}
              {item.time && <Td>{item.time}</Td>}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default CustomTable;
