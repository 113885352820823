import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import AreaChart from "../components/chart";
import BarChart from "../components/barChart";
import CustomTable from "../components/customTable";

const Info = () => {
  const poolHeaders = ["#", "Pool", "Volume 24H", "TVL", "Volume 7D"];
  const poolData = [
    {
      title: "Top Pools",
      name: { name: "Item 1", imgSrc: ["ether.png", "usdt.png"] },
      tvl: "$1,000,000",
      //   price: "$10.99",
      //   priceChange: "+3.25%",
      volume24h: "100,000",
      volume7D: "200,000",
    },
    {
      title: "Top Tokens",
      name: { name: "Item 2", imgSrc: ["ether.png", "usdt.png"] },
      tvl: "$800,000",
      //   price: "$15.50",
      //   priceChange: "-1.75%",
      volume24h: "75,000",
      volume7D: "750,000",
    },
    {
      title: "Top Tokens",
      name: { name: "Item 3", imgSrc: ["ether.png", "usdt.png"] },
      tvl: "$1,200,000",
      //   price: "$8.20",
      //   priceChange: "+2.10%",
      volume24h: "120,000",
      volume7D: "220,000",
    },
    {
      title: "Top Tokens",
      name: { name: "Item 4", imgSrc: ["ether.png", "usdt.png"] },
      tvl: "$900,000",
      //   price: "$12.75",
      //   priceChange: "-0.50%",
      volume24h: "90,000",
      volume7D: "900,000",
    },
  ];
  const transactionHeaders = [
    "All",
    "Total Value",
    "Token Amount",
    "Token Amount",
    "Account",
    "Time",
  ];
  const transactionData = [
    {
      title: "Transactions",
      name: { name: "Item 1" },
      totalValue: "$1,000,000",
      //   price: "$10.99",
      //   priceChange: "+3.25%",
      tokenAmount1: "100,000",
      tokenAmount2: "200,000",
      account: "0x8888989",
      time: "4h",
    },
    {
      title: "Top Tokens",
      name: { name: "Item 2" },
      totalValue: "$800,000",
      //   price: "$15.50",
      //   priceChange: "-1.75%",
      tokenAmount1: "75,000",
      tokenAmount2: "750,000",
      account: "0x8888989",
      time: "6h",
    },
    {
      title: "Top Tokens",
      name: { name: "Item 3" },
      totalValue: "$1,200,000",
      //   price: "$8.20",
      //   priceChange: "+2.10%",
      tokenAmount1: "120,000",
      tokenAmount2: "220,000",
      account: "0x8888989",
      time: "7h",
    },
    {
      title: "Top Tokens",
      name: { name: "Item 4" },
      totalValue: "$900,000",
      //   price: "$12.75",
      //   priceChange: "-0.50%",
      tokenAmount1: "90,000",
      tokenAmount2: "900,000",
      account: "0x8888989",
      time: "9h",
    },
  ];

  return (
    <Box
      maxW="1212px"
      mx="auto"
      py="80px"
      mt={{ base: "0px", md: "0px" }}
      px={{base:"20px", md:'10px'}}
      overflowX={"hidden"}
    >
      <Flex
        align={"center"}
        justify={"space-between"}
        gap="50px"
        direction={{ base: "column", lg: "row" }}
        //   bgGradient="radial(#10655E, #191B1F, #191B1F,)"
      >
        <AreaChart />
        <BarChart />
      </Flex>
      {/* <CustomTable data={tokenData} headers={tokenHeaders} /> */}
      <CustomTable data={poolData} headers={poolHeaders} />
      <CustomTable data={transactionData} headers={transactionHeaders} />
    </Box>
  );
};

export default Info;
